@import url('https://fonts.googleapis.com/css2?family=Italiana&family=Montserrat:wght@100&display=swap'); 




.project-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: project-content-animation 1s ease 0s 1 normal forwards;
}

.project-title {
  font-family: "Montserrat";
}

.project-p {
  font-family: "Montserrat";
  margin: 0 20px 40px 20px;
}

@keyframes project-content-animation {
	0% {
		opacity: 0;
		transform: translateY(250px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@media only screen and (min-width:601px) and (max-width:2400px) {

	
	.project-title {
		font-size: 40px;
	}
	
	.project-p {
		font-size: 20px;
		width: 50%;
		margin: -20px 0 80px 0;
	}

}