@import url('https://fonts.googleapis.com/css2?family=Italiana&family=Montserrat:wght@100&display=swap'); 


.aboutpage-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dark-mode-about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #231F20;
  color:#FAF4F0;
}

.about-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.aboutpage-title {
  font-size: 80px;
  font-family: "Italiana";
  font-weight: 100;
  margin-right: 20px;
  animation: about-header-animation 1s ease 0s 1 normal forwards;
}

img {
  height: 135px;
  width: 105px;
  margin-top: 20px;
}

.description {
  font-family: "Italiana";
  margin: 0 30px 40px 30px;
  font-weight: 100;
}

.about-me {
  font-family: "Montserrat";
  margin: 0 30px 0 30px;
}

.about-me-2 {
  font-family: "Montserrat";
  margin-right: 30px;
  margin-left: 30px;
}

.stack {
  font-family: "Italiana";
}

p {
  font-family: "Italiana";
  list-style: none;
}

.stack-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  margin-bottom: 40px;
  width: 80%;
}

.dark-mode-stack-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;
  margin-bottom: 40px;
  width: 80%;
  background-color: #231F20;
  color:#FAF4F0;
}

.about-contact-link, .about-prj-link {
  font-family: "Italiana";
  font-size: 15px;
  color: #FAF4F0;
  background-color: #231F20;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 0 0 10px 0;
  width: 100px;
  text-align: center;
  text-decoration: none;
}

.dark-mode-about-links {
  font-family: "Italiana";
  font-size: 15px;
  color: black;
  background-color: #FAF4F0;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 0 0 10px 0;
  width: 100px;
  text-align: center;
  text-decoration: none;
}

.about-contact-link:visited, .about-prj-link:visited {
  color: #FAF4F0;
}

.about-contact-link:active, .about-prj-link:active {
  color: #FAF4F0;
}

.about-contact-link:hover, .about-prj-link:hover {
  opacity: 0.5;
}

.dark-mode-about-links:hover {
  opacity: .5;
}


@keyframes about-header-animation {
	0% {
		opacity: 0;
		transform: translateX(-250px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
/* Tablet */
@media only screen and (min-width:600px) and (max-width:1000px) {
  .about-row {
    justify-content:space-between;
    width: 100vw;
  }
  
  .aboutpage-title {
    font-size: 150px;
    margin-left: 40px;
    margin-top: 150px;
  }
  
  img {
    height: 400px;
    width: 310px;
    margin-right: 20px;
  }
  
  .description {
    width: 50%;
    font-size: 42px;
    margin: -120px 300px 200px 0;
  }
  
  .about-me {
    font-size: 20px;
    width: 50%;
    margin: -150px 300px 200px 0;
    line-height: 25px;
  }
  
  .about-me-2 {
    font-size: 20px;
    width: 50%;
    margin: -160px 300px 200px 0;
    line-height: 25px;
  }
  
  .stack {
    font-size: 35px;
    margin-top: -80px;
  }
  
  p {
    font-size: 20px;
  }
  
  .stack-container {
    width: 100%;
    margin-top: 10px;
  }
  
 .about-contact-link, .about-prj-link {
    font-size: 20px;
    padding: 15px;
    width: 150px;
    margin-bottom: 30px;
  }

}
/* Desktop */
@media only screen and (min-width:1000px) and (max-width:2400px) {
  .about-row {
    display: flex;
    justify-content: space-around;
    width: 100vw;
  }
  
  .aboutpage-title {
    font-size: 150px;
    margin-left: 60px;
  }
  
  img {
    height: 400px;
    width: 310px;
  }
  
  .description {
    width: 50%;
    font-size: 42px;
    margin: -120px 300px 200px 0;
  }
  
  .about-me {
    font-size: 20px;
    width: 50%;
    margin: -150px 300px 200px 0;
    line-height: 25px;
  }
  
  .about-me-2 {
    font-size: 20px;
    width: 50%;
    margin: -160px 300px 200px 0;
    line-height: 25px;
  }
  
  .stack {
    font-size: 35px;
    margin-top: -80px;
  }
  
  p {
    font-size: 25px;
  }
  
  .stack-container {
    width: 80%;
    margin-top: 10px;
  }
  
  .about-contact-link, .about-prj-link {
    font-size: 20px;
    padding: 15px;
    width: 200px;
    margin-bottom: 30px;
  }
}