@import url('https://fonts.googleapis.com/css2?family=Italiana&family=Montserrat:wght@100&display=swap'); 

body {
  animation: home-content-animation 1s ease 0s 1 normal forwards;
  background-color:#FAF4F0;
}


.homepage-link-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 10px;
}

.home-name {
  font-family: "Montserrat";
  margin: 100px 0 0 30px;
}

.home-job-title {
  font-family: "Montserrat";
  margin: 5px 0 30px 30px;
}

.link-styles {
  font-family: "Italiana";
  font-size: 80px;
  text-decoration: none;
   color: black; 
}

.dark-mode-styles {
  font-family: "Italiana";
  font-size: 80px;
  text-decoration: none;
  color: white;
}

.dark-mode-styles:visited {
  color: white;
} 

.dark-mode-styles:active {
  color: white;
} 

.link-styles:visited {
  color: black;
} 

.link-styles:active {
  color: black;
} 

.link-styles:hover {
  font-style: italic;
}

.dark-mode-styles:hover {
  font-style: italic;
}

@keyframes home-content-animation {
	0% {
		opacity: 0;
		transform: translateY(-250px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@media only screen and (min-width:601px) and (max-width:2400px) {

  .homepage-link-container {
    display: flex;
    flex-direction: column;
    align-items:left;
  }

  .link-styles {
    font-size: 150px;
  }
  
  .name-jobtitle-container {
    display: flex;
    flex-direction: column;
    align-items:right;
    margin-left: 150px;
  }
  
  .homepage-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 100px 50px 0 0;
  }
  
  .home-name {
    font-size: 50px;
  }
  
  .home-job-title {
    font-size: 25px;
  }
}