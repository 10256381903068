html,
body,
#root {
  background-color: #FAF4F0;
  height: 100%;
  width: auto;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
  z-index: 2;
}