 @import url('https://fonts.googleapis.com/css2?family=Italiana&family=Montserrat:wght@100&display=swap'); 

 nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
 }

 .nav-left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
 }
 
 .nav-link {
  font-family: "Montserrat";
  font-size: 15px;
  text-decoration: none;
  margin-right: 5px;
 }

 .dark-mode-nav-link {
  font-family: "Montserrat";
  font-size: 15px;
  text-decoration: none;
  margin-right: 5px;
  color: white;
 }

 .nav-link:visited {
  color:black;
 }

 .nav-link:active {
  color: black;
 }

 .nav-link:hover {
  text-decoration: line-through;
 }

 .dark-mode-nav-link:hover {
  text-decoration: line-through;
 }

 .nav-line {
  width: 50px;
  height: 2px;
  margin: 0;
  background-color: black;
 }

 .dark-mode-nav-line {
  width: 50px;
  height: 2px;
  margin: 0;
  background-color: white;
 }

 .nav-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;  
  font-family: "Montserrat";
  align-items: center;
 }
 
 .copy-icon {
  height: 10px;
  width: 10px;
 }

@media only screen and (min-width:601px) and (max-width:2400px) {

  .nav-btn {
    font-size: 20px;
  }

  .nav-line {
    width: 150px;
    height: 1px;
    margin: 1px 10px 0 40px;
  }

  .copy-icon {
    font-size: 15px;
  }

  .nav-right {
    font-size: 20px;
    align-items: center;
   }
   
   .copy-icon {
    height: 15px;
    width: 15px;
   }
}