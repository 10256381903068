@import url('https://fonts.googleapis.com/css2?family=Italiana&family=Montserrat:wght@100&display=swap'); 
.contact-content-container {
  background-color: #231F20;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 80px 0 100px 0;
  margin: 70px 0 0 0;
  animation: contact-content-animation 1s ease 0s 1 normal forwards;
}

.dark-mode-contact-container {
	background-color: #231F20;
  color: white;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  margin: 70px 0 0 0;
  animation: contact-content-animation 1s ease 0s 1 normal forwards;
}

.contact-top-text-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.connect-title {
  font-family: "Italiana";
  font-weight: 100;
  margin-left: 20px;
  animation: connect-stmt-animation 2s ease 0s 1 normal forwards;
}

.connect-p {
  font-family: "Montserrat";
  color: #FAF4F0;
  margin: 30px 20px 10px 20px;
}

.contact-page-link-container {
	display: flex;
	justify-content: space-evenly;
	margin-top: 50px;
}

.contact-links {
	color: #FAF4F0;
}

.contact-links:visited {
	color: #FAF4F0;
}

.contact-links:active {
	color: #FAF4F0;
}

.contact-links:hover {
	opacity: 0.5;
}

.contact-icon {
	height: 2em;
	width: 2em;
	color: #FAF4F0;
}

@keyframes connect-stmt-animation {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateY(-45px);
	}

	24% {
		opacity: 1;
	}

	40% {
		animation-timing-function: ease-in;
		transform: translateY(-24px);
	}

	65% {
		animation-timing-function: ease-in;
		transform: translateY(-12px);
	}

	82% {
		animation-timing-function: ease-in;
		transform: translateY(-6px);
	}

	93% {
		animation-timing-function: ease-in;
		transform: translateY(-4px);
	}

	25%,
	55%,
	75%,
	87% {
		animation-timing-function: ease-out;
		transform: translateY(0px);
	}

	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes contact-content-animation {
	0% {
		opacity: 0;
		transform: translateX(-250px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@media only screen and (min-width:601px) and (max-width:2400px) {

	.connect-title {
		font-size: 50px;
	}

	.connect-p {
		font-size: 25px;
	}

	.contact-links {
		height: 80px;
		width: 80px;
	}

	.contact-icon {
		height: 3em;
		width: 3em;
	}

}