@import url('https://fonts.googleapis.com/css2?family=Italiana&family=Montserrat:wght@100&display=swap'); 

.project-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  background-color: #231F20;
  width: 100vw;
  height: 300px;
  margin-top: 20px;
}

.project-card-links {
  color: #FAF4F0;
  font-family: "Italiana";
  font-size: x-large;
  font-weight: 100;
  text-decoration: none;
}

.project-card-links:visited {
  color: #FAF4F0;
}

.project-card-links:active {
  color: #FAF4F0;
}

.project-card-links:hover {
  font-style: italic;
}

@media only screen and (min-width:601px) and (max-width:2400px) {

  .project-card-container {
    width: 100vw;
    height: 500px;
    margin-top: 0;
  }

  .project-card-links {
    font-size: 80px;
  }

}